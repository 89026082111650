import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageRigaBee1 from './imageRigaBee1';

import "./header.css";

function Header ({ siteTitle }) {

  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-1">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <div className="inline-block px-2" style={{ width: `65px`, height: `65px` }} >
          <ImageRigaBee1 class="fill-current h-8 w-8 mr-2" width="54" height="54" />
        </div>
        <Link to="/members/"  className="font-semibold text-xl tracking-tight">{ siteTitle }</Link>
      </div>

      <div className="block lg:hidden">
        <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div>

      <div className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
        <div className="text-sm lg:flex-grow">
          <Link to="/members/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Home
          </Link>
          <Link to="members/events/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Events
          </Link>
          <Link to="members/entertainers/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Entertainers
          </Link>
          <Link to="members/producers/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Producers
          </Link>
          <Link to="members/livestreaming/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Live Streaming
          </Link>
        </div>

        <div>
          <a href="/" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">
            Logout
          </a>
        </div>
      </div>
    </nav>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;