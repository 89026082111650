/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './members-header';
import './layout.css';
import firebase from './firebase'
import { navigate } from 'gatsby';

const MembersLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MembersSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    if (firebase.getCurrentUserName() == null) {
      navigate('/');
    };
  });

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}>
        <main>{children}</main>
        <footer>
        &copy; RigaBee, LLC {new Date().getFullYear()}
        </footer>
      </div>
    </>
  );
};

MembersLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MembersLayout;
