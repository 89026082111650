import React from 'react';
//import { Link } from 'gatsby';

import Layout from '../../components/members-layout';
import SEO from '../../components/seo';
import firebase from '../../components/firebase';

const ProducersMembersPage = () => (
  <Layout>
    <SEO title="Producers" />
    <h1>{firebase.getCurrentUserName()} - Keep checking back.</h1>
    <p>RigaBee will be launching soon!</p>
  </Layout>
);

export default ProducersMembersPage;
